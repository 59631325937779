<template>
  <h1>DASHBOARD</h1>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">Bienvenido!</div>
        <div class="card-body">
          <p>
            En este tablero podrá tabajar en las diferentes tareas que se realizan en el club.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
